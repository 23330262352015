$('.page-cover').each((i,el) => {
    const cta = $(el).find('.cta')
    const autoScrollEnabled = $(el).data('autoscroll') != undefined ? $(el).data('autoscroll') : true

    const scrollToContent = function() {
        let offset = 0
        if (Helper.Device.isSmallScreen()) offset = App.heights.header

        // const scrollEl = Scroll.getScrollingElement()

        gsap.to(window, {
            duration: 1.5,
            ease: "power3",
            scrollTo: {
                y: $(el).next().position().top,
                offsetY: offset
            }
        })
    }

    cta.on('click', scrollToContent)

    console.log($(el).data('autoscroll'), autoScrollEnabled)
    
    if (Scroll.scrollTop() === 0 && autoScrollEnabled) {
        let autoScroll = setTimeout(scrollToContent, 6000);
        $(document).one('scroll', function() {
            clearTimeout(autoScroll)
        })
    }
})