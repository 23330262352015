import MicroModal from 'micromodal';

const Modal = (ctx) => {
    const el = ctx.el

    MicroModal.init();

    App.openModal = function(e) {
        e.preventDefault()

        const contentBase64 = $(e.target).data('content')
        const content = atob(contentBase64)
        const href = $(e.target).data('href')
        const relativePath = '/' + href.slice(href.indexOf('/media') + 1);

        $('#modal-1 #code').html(content)
        $('#modal-1 #copyText').attr('value', content)
        $('#modal-1 #download').attr('href', relativePath)

        MicroModal.show('modal-1')
    }

    $(el).find('#copy').on('click', function (e) {
        e.preventDefault();
        // Get selection.
        let copyText = document.querySelector('#modal-1 #copyText');
        const successMessage = $(el).find('#copy').data('success')
        
        // Select the text field
        copyText.select();
        copyText.setSelectionRange(0, 99999); // For mobile devices

        // Copy the text inside the text field
        navigator.clipboard.writeText(copyText.value);

        $(el).find('#copy').text(successMessage)
        setTimeout(() => {
            $(el).find('#copy').text('Kopieren')
        },1000)
    });
}

export default Modal