import { Swiper, Navigation } from 'swiper';
Swiper.use([Navigation]);

import PhotoSwipeLightbox from '../../../node_modules/photoswipe/dist/photoswipe-lightbox.esm.js';

const Gallery = (ctx) => {
    const el = ctx.el
    const slidesOffsetBefore = !Helper.Device.isMobile() ? el.offsetWidth / 6 : 0
    const spaceBetween = Helper.Device.isMobile() ? 14 : 0
    
    const swiper = new Swiper(el, {
        navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
        },
        slidesPerView: 'auto',
        slidesOffsetBefore: slidesOffsetBefore,
        spaceBetween: spaceBetween
    });

    if (!Helper.Device.isSmallScreen()) {
        inView('.swiper-container')
        .on('enter', el => {
            $('.header-controls').addClass('bg-white')
        })
        .on('exit', el => {
            $('.header-controls').removeClass('bg-white')
        });
    }

    const lightbox = new PhotoSwipeLightbox({
        gallery: '#'+el.id,
        children: 'a',
        pswpModule: () => import('photoswipe')
    });
    lightbox.init();

    lightbox.on('change', () => {
        const { pswp } = lightbox;
        swiper.slideTo(pswp.currIndex, 0, false);
    });
}

export default Gallery