const Accordion = (ctx) => {
    const el = ctx.el

    let params = null
    if (ctx?.get) params = ctx.get()

    let openOnInit = false
    if (ctx?.open) openOnInit = ctx.open
    if (params) openOnInit = params?.showabstracts

    const bodyMaxHeight = Helper.Device.isMobile() ? 400 : 600;
    const fixedHeight = ctx.modifiers && ctx.modifiers.hasOwnProperty('fixed')

    const label = $(el).find('.accordion-label')[0]
    const body = $(el).find('.accordion-body')[0]
    const parent = $(el).parent('.grid-item')

    const buttonText = $(label).data('button-text') ? JSON.parse(Helper.Base64.decode($(label).data('button-text'))) : null

    const close = () => {
        $(body).removeClass('scroll')
        $(body).css('height','');

        $(parent).removeClass('is-expanded');
        $(el).removeClass('is-expanded')

        if (buttonText) $(label).text(buttonText.hide)
    }

    const open = () => {
        const scrollHeight = $(body)[0].scrollHeight

        if (fixedHeight) {
            const maxHeight = bodyMaxHeight 
            $(body).css('height', maxHeight);
        } else {
            const maxHeight = scrollHeight
            $(body).css('height', maxHeight);
        }
        
        // avoid jumping of content because of scrollbar
        setTimeout(() => {
            $(body).addClass('scroll')
        },500)


        $(parent).addClass('is-expanded');
        $(el).addClass('is-expanded')

        if (buttonText) $(label).text(buttonText.show)
    }

    const onToggle = (e) => {
        const hasMaxHeight = $(body)[0].style.height

        hasMaxHeight != "" ? close() : open()

        setTimeout(() => {
            const grid = $(el).parents('.masonry-grid')
            if (grid.length !== 0) grid[0].masonry.layout()

            //setAsideDiff()
        },500)
    }

    $(label).on('click', onToggle)

    if (ctx?.effect) {
        ctx.effect(() => {
            const params = ctx.get()
            params?.showabstracts ? open() : close()
        })
    }

    openOnInit === true ? open() : close()
}

export default Accordion