const MobileAside = (ctx) => {
    if (window.innerWidth > App.mobileBreak) return

    const getDocHeight = function() {
        $(ctx.el).css({"--doc-h": window.innerHeight + "px"})
    }

    $(ctx.el).addClass('mobile-aside')

    setTimeout(getDocHeight,100)
    window.addEventListener('resize', getDocHeight)

    const button = $(ctx.el).find("button.aside-toggle")
    console.log(button)
    if (button.length === 0) return
    const buttonTextObj = JSON.parse(Helper.Base64.decode($(button).data('button-text')))

    const asideCollapse = $(ctx.el).find('.aside-collapse')

    ctx.effect(() => {
        const show = ctx.get()
        if (show) {
            Scroll.disable()
            $(asideCollapse).addClass('show')
            button.text(buttonTextObj.show)
        } else {
            Scroll.enable()
            $(asideCollapse).removeClass('show')
            button.text(buttonTextObj.hide)
        }
    })
}

export default MobileAside