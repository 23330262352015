const SocialSharer = (ctx) => {
    const el = ctx.el
    const btn = $(el).find('button')

    const closeSharer = function() {
        gsap.to('.share-options a', {
            duration: .2,
            stagger: {
                each: 0.015
            },
            x: -15,
            opacity: 0,
            onComplete: () => {
                $(el).removeClass('open')
            }
        })
    }

    const openSharer = function() {
        gsap.fromTo('.share-options a', {
            opacity: 0,
            x: -15
        }, {
            duration: .2,
            stagger: {
                each: 0.015
            },
            x: 0,
            opacity: 1,
            onStart: () => {
                $(el).addClass('open')
            }
        })
    }

    $(btn).on('click', function() {
        if ($(el).hasClass('open')) {
            closeSharer()
        } else {
            openSharer()
        }
    })

    // click outside to close
    document.addEventListener('click', (e) => {
        if ($(e.target).parents('.sharing-wrapper').length > 0) return
        closeSharer()
    })
}

export default SocialSharer