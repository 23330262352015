const VideoBlock = (ctx) => {
    const el = ctx.el
    const consent = $(el).find('.dialog.video')
    const posterEl = $(el).find('.video-poster img')
    // const iframe = $(el).find('.embed-video')

    const hosts = [
        {
            //reg: /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=|\?v=)([^#\&\?]*).*/i,
            //reg: /^.*(?:(?:v|vi|be|videos|embed)\/(?!videoseries)|(?:v|ci)=)([\w-]{11})/i,
            reg:/^((?:https?:)?\/\/)?((?:www|m)\.)?((?:youtube\.com|youtu.be))(\/(?:[\w\-]+\?v=|embed\/|v\/)?)([\w\-]+)(\S+)?$/i,
            host: 'yt',
            url: 'https://www.youtube.com/embed/$5',
            params: {
                // 'picture-in-picture': 1,
                // accelerometer: 1,
                // gyroscope: 1,
                autoplay: 1,
                rel: 0
            },
            id(m) {
                return m[5]
            },
            thumb(videoID) {
                return new Promise((resolve,reject) => {
                    resolve('https://img.youtube.com/vi/' + videoID + '/maxresdefault.jpg')
                })
            }
        },
        {
            reg: /^.*vimeo.com\/(\d+)($|\/|\b)/i,
            url: 'https://player.vimeo.com/video/$1',
            host: 'vi',
            params: {
                title: 0,
                byline: 0,
                portrait: 0,
                autoplay: 1
            },
            id(m) {
                return m[1]
            },
            thumb: function(videoID) {
                return new Promise((resolve,reject) => {
                    var xhr = new XMLHttpRequest();
                    xhr.open("GET", "https://vimeo.com/api/v2/video/"+ videoID +".json", true);
                    xhr.onload = function (e) {
                        if (xhr.readyState === 4) {
                            if (xhr.status === 200) {
                                var data = xhr.responseText;
                                var parsedData = JSON.parse(data);
                                resolve(parsedData[0].thumbnail_large);

                                var thumbSRClarge = parsedData[0].thumbnail_large;
                                // split url of large thumbnail at 640
                                var thumbSplit = thumbSRClarge.split(/\d{3}(?=.jpg)/);
                                // add 1280x720 to parts and get bigger thumbnail
                                resolve(thumbSplit[0] + '1280x720' + thumbSplit[1]);
                                // thumbSRC = thumbSplit[0] + '1280x720' + thumbSplit[1];
                                // thumbIMG.src = thumbSRC;
                                // thumbLINK.href = thumbSRC;
                            } else {
                                console.error(xhr.statusText);
                            }
                        }
                    };
                    xhr.onerror = function (e) {
                        console.error(xhr.statusText);
                        reject()
                    };
                    xhr.send(null);
                })
            }
        },
        {
            reg: /^.*(?:\/video|dai.ly)\/([A-Za-z0-9]+)([^#\&\?]*).*/i,
            host: 'dm',
            url: 'https://www.dailymotion.com/embed/video/$1',
            params: {
                autoplay: 0
            }
        },
        {
            reg: /^.*coub.com\/(?:embed|view)\/([A-Za-z0-9]+)([^#\&\?]*).*/i,
            host: 'co',
            url: 'https://coub.com/embed/$1',
            params: {
                autoplay: 0
            }
        },
        // {
        //     reg: /^.*twitch.tv\/videos\/(\d+)($|\/|\b)/i,
        //     url: 'https://player.twitch.tv/?autoplay=false&video=v$1',
        //     params: {
        //         autoplay: false
        //     },
        //     callback: function () {
        //         return 'https://player.twitch.tv/?video=v$1';
        //     }
        // }
    ]

    const posterSrc = $(posterEl).attr('src')
    const url = $(el).data('url')
    const host = hosts.find(host => host.reg.exec(url))
    const videoId = host.id(host.reg.exec(url))

    const params = Object.assign({}, host.params);//, this.params);
    const query = Object.keys(params).map(key => key + '=' + params[key]).join('&');
    const and = host.url.indexOf('?') >= 0 ? '&' : '?';
    const src = url.replace(host.reg, host.url) + and + query;

    const initIFrame = function() {
        const iframe = document.createElement('iframe')
        $(iframe).attr('src',src)
        $(iframe).addClass('embed-video')
        $(iframe).attr('allowfullscreen')

        $(el).append(iframe)
        $(el).addClass('play')
    }
    
    if (!posterSrc) {
        let thumbSrc = ""
        host.thumb(videoId)
        .then(res => {
            thumbSrc = res
            console.log(thumbSrc)
            $(posterEl).attr('src',thumbSrc)
        })
    }

    $(el).find('.video-poster .play-button').on('click', function() {
        if (App.consent.video) {
            initIFrame()
        } else {
            $(consent).addClass('visible')
        }
    })

    $(consent).find('button#dialog-reject').on('click', function() {
        Cookies.set('kwb-video', 'reject')
        $(consent).removeClass('visible')
    })
    
    $(consent).find('button#dialog-confirm').on('click', function() {        
        Cookies.set('kwb-video', 'confirm')        
        $(consent).removeClass('visible')
        initIFrame()
    })
}

export default VideoBlock