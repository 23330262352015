import Accordion from './modules/Accordion.js'
import Form from './modules/Form.js'
import Gallery from './modules/Gallery.js'
import Video from './modules/Video.js' 
import MobileAside from './modules/MobileAside.js'
import SocialSharer from './modules/SocialSharer.js'
import Modal from './modules/Modal.js'
import MasonryGrid from './modules/ResultsMasonry.js'
// import LogoImage from './modules/LogoImage.js'

import './modules/PageCover';

window.addEventListener('load', function() {

    if (typeof createApp === 'undefined') {
        $('[v-accordion]').each((i,el) => Accordion({el: el}))
        $('[v-gallery]').each((i,el) => Gallery({el: el}))
        $('[v-video]').each((i,el) => Video({el: el}))
        $('[v-sharer]').each((i,el) => SocialSharer({el: el}))
        $('[v-image]').each((i,el) => Image({el: el}))
    } else {
        createApp({
            showaside: false
        })
        .directive('accordion', Accordion)
        .directive('form', Form)
        .directive('gallery', Gallery)
        .directive('video', Video)
        .directive('mobile-aside', MobileAside)
        .directive('sharer', SocialSharer)
        .directive('modal', Modal)
        .directive('masonry', MasonryGrid)
        // .directive('logo', LogoImage)
        .mount('main')
    }

})