const Form = (ctx) => {
  const el = ctx.el;
  const form = $(el).find('form');
  const textareas = $(el).find('textarea');
  var errorMessage = 'Folgende Zeichen sind nicht erlaubt: ":"';

  textareas.on("input change propertychange", function() {

      var pattern = $( this ).attr( "pattern" );

      if(typeof pattern !== typeof undefined && pattern !== false)
      {
          var patternRegex = new RegExp(pattern.replace(/^\^|\$$/g, ''), "g" );
          var match = $(this).val().match(patternRegex)
          var hasError = match && match.length !== 0;

          if ( typeof this.setCustomValidity === "function") 
          {
              this.setCustomValidity( hasError ? errorMessage : "" );
          } 
          else 
          {
              $( this ).toggleClass( "error", !!hasError );
              $( this ).toggleClass( "ok", !hasError );

              if ( hasError ) 
              {
                  $( this ).attr( "title", errorMessage );
              } 
              else
              {
                  $( this ).removeAttr( "title" );
              }
          }
      }

  });

  $(form).on('submit', function (e) {
    e.preventDefault();
    console.log("submit", e, this)
    const fbform = e.target
    const msgBox = $('.messagebox');

    fetch(fbform.action,{
      body: new FormData(fbform),
      method: 'POST'
    })
    .then(response => {
      console.log(response.ok)
      if(response.ok) {
        // We have reached the formhandler script,
        // and have received a response - simply pass it to the next 'then' method:
        return response.json();
      } else {
        // We have received an error - such as a 500 error from the server -
        // so we throw an Error to pass it to the 'catch' method below:
        let warning = 'Unidentified Processing Error';
        if(response.headers.has('Warning')){
            warning = response.headers.get('Warning');
        };
        throw new Error(response.status + ' | ' + warning);
      }
    })
    .then(data => {
      console.log(data)

      // truly successfull response:
      // msgBox.innerHTML = `<?= $pg->fb_success_msg()->kt(); ?>`;
      msgBox.removeClass('error');
      
      $(this.el).addClass('submitted')
      setTimeout(() => {
        $(fbform).hide()
        window.scrollTo(0,$(fbform).offset().top)
      }, 500)
    })
    .catch(error => {
      // Display error message along with response error info:
      // let msg = `<?= $pg->fb_error_msg()->kt(); ?>`;
      // msgBox.innerHTML = msg;
      // let errorMsg = document.createElement('p');
      // errorMsg.textContent = error;
      // msgBox.appendChild(errorMsg);
      msgBox.addClass('error');

      console.log(error)
    });
    msgBox.removeAttr('hidden');
  });
}

export default Form